import { applicationData } from 'src/app/models/app.data.model';
import { Constants } from 'src/app/shared/constants';

export const APPDATA: applicationData[] = [
    { cnxtId: 'ci1', primaryKey: 'access_token' },
    { cnxtId: 'ci2', primaryKey: 'fname' },
    { cnxtId: 'ci3', primaryKey: 'selected_site' },
    { cnxtId: 'ci4', primaryKey: 'uid' },
    { cnxtId: 'ci5', primaryKey: 'role' },
    { cnxtId: 'ci6', primaryKey: 'msg' },
    { cnxtId: 'ci7', primaryKey: 'username' },
    { cnxtId: 'ci8', primaryKey: 'refresh_token' },
    { cnxtId: 'ci9', primaryKey: 'lname' },
    { cnxtId: 'ci10', primaryKey: 'urn' },
    { cnxtId: 'ci11', primaryKey: 'modalmsg' },
    { cnxtId: 'ci12', primaryKey: 'login_status' },
    { cnxtId: 'ci13', primaryKey: 'userType' },
    { cnxtId: 'ci14', primaryKey: Constants.ACCESS_TOKEN },
    { cnxtId: 'ci15', primaryKey: Constants.SESSION_ID },
    { cnxtId: 'ci16', primaryKey: Constants.CURRENT_SITE_IMAGE_INFO },
    { cnxtId: 'ci17', primaryKey: Constants.RESEND_EMAIL },
    { cnxtId: 'ci18', primaryKey: Constants.PERFORMANCE_SELECTED_TAB },
    { cnxtId: 'ci19', primaryKey: Constants.MY_DASHBOARD_SELECTED_DEVICE },
    { cnxtId: 'ci20', primaryKey: Constants.TOTAL_REGISTERED_SITES },
    { cnxtId: 'ci21', primaryKey: 'credentials'},
    { cnxtId: 'ci22', primaryKey: Constants.GATEWAY_AFTER_LOGIN_TIMER},
    { cnxtId: 'ci23', primaryKey: Constants.FORCE_FIRMWARE_UPGRADE},
    { cnxtId: 'ci24', primaryKey: Constants.FORCE_FIRMWARE_STATUS},
    { cnxtId: 'ci25', primaryKey: Constants.TOKEN_EXPIRY}
]